// @flow

type Attrib = {
  +key: string,
  +value: string
}

type Config = {
  +id: string,
  +url: string,
  +attribs?: Array<Attrib>,
  +callbackDelay?: ?number
}

function loadScript(config: Config, callback?: () => void) {
  const {id, url, async, defer, attribs, callbackDelay} = config;
  const existingScript = document.getElementById(id);

  if (!existingScript) { 
    const script = document.createElement('script');

    script.src = url; // URL for the third-party library being loaded.
    script.id = id; // e.g., googleMaps or stripe
    script.async = async;
    script.defer = defer;

    if (attribs) {
      for (let a of attribs) {
        script.setAttribute(a.key, a.value);
      }
    }

    //document.body.appendChild(script);
    const firstScriptInDoc = document.scripts[0];

    // undefined in tests...
    if (firstScriptInDoc) {
      firstScriptInDoc.parentNode.insertBefore(script, firstScriptInDoc);
    } else {
      // TODO: review if needed?!
      document.body.appendChild(script);
    }

    script.onload = () => {
      if (callback) {
        if (callbackDelay) {
          setTimeout(() => {
            callback();
          }, callbackDelay);
        } else {
          callback();
        }
      }
    };

  }

  if (existingScript && callback) callback();
}

export default loadScript;
