// @flow

import logger from './logger';

let stripe;

function _createInstance() {
  if (!window.Stripe) {
    logger.warn('_createInstance, Stripe.js is NOT loaded.');
    return null;
  }
  logger.info('_createInstance, Stripe.js is loaded.');
  return window.Stripe(process.env.GATSBY_STRIPE_CLIENT_KEY);
}

function setInstance(instance: ?Object) {
  if (instance) {
    stripe = instance;
  }
}

function getInstance() {

  if (!stripe) {
    stripe = _createInstance();
  }

  return stripe;
}

export default {
  getInstance,
  setInstance
};
 
