// @flow

import type {ContactUsAction, EstimatePriceAction} from '../../types';
import {call, put} from 'redux-saga/effects';
import {contactUsSuccess, estimatePriceSuccess} from '../actions/miscActions';

import axios from 'axios';
import {fireError} from '../actions/statusActions';
import { message } from 'antd';

export function* contactUs(action: ContactUsAction): Generator<*, void, *> {

  const URL = `${process.env.GATSBY_API_URL}/v2/messages`;

  const {email, resetFields} = action.payload;

  try {
    yield call(axios.post, URL, {
      email,
      message: action.payload.message
    });
 
    message.success('The message has been sent. We will be back to you as soon as possible.')
    yield put(contactUsSuccess());
    yield call(resetFields);
  } catch (e) {
    const serverErrMsg = e.response && e.response.data ? e.response.data : e.message;
    const fullMsg = `Failed to send the message. ${serverErrMsg}`;
    message.error(fullMsg);
    yield put(fireError(fullMsg, 'CONTACT_US'));
  }
}

export function* estimatePrice(action: EstimatePriceAction): Generator<*, void, *> {

  const { fromCountry, toCountry, numPages, extraService } = action.payload;

  const URL = `${process.env.GATSBY_API_URL}/v2/mail/letters/price-estimate?fromCountry=${fromCountry}&toCountry=${toCountry}&numPages=${numPages}&extraService=${extraService || ''}`;

  try {
    const res = yield call(axios.get, URL);

    // TODO: remove later
    //yield call(_trackConversion);

    yield put(estimatePriceSuccess(res.data.price));
  } catch (e) {
    const serverErrMsg = e.response && e.response.data ? e.response.data : e.message;
    const fullMsg = `Failed to check the price. ${serverErrMsg}`;
    message.error(fullMsg);
    yield put(fireError(fullMsg, 'ESTIMATE_PRICE'));
  }
}

// TODO: remove later - testing only
// function* _trackConversion() {
//   if (window.gtag && process.env.GATSBY_GTAG_ID) {
//     try {
//       logger.info(`trying dummy conversion, dataLayer = ${JSON.stringify(window.dataLayer)}`)
//
//       window.gtag('event', 'conversion', {
//         'send_to': `${process.env.GATSBY_GTAG_ID}/${process.env.GATSBY_AD_CONVERSION_ID}`,
//         'value': 0.13, // DUMMY
//         'currency': 'GBP',
//         'transaction_id': 'dummyId',
//         'event_callback': () => {
//           logger.info('_trackConversion event_callback fired');
//         }
//       });
//     } catch(e) {
//       logger.warn(`Failed to track conversion ${JSON.stringify(e)}`)
//     }
//   } else {
//     logger.warn(`Cannot track conversion, gtag - ${window.gtag}, gtatId - ${process.env.GATSBY_GTAG_ID}`);
//   }
// }
