// @flow

export const isPrerender = typeof window === `undefined`;

export function addDays(date: Date, days: number): Date {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

export function omit(obj: Object, omitKey: string): Object {
  return Object.keys(obj).reduce((result, key) => {
    if(key !== omitKey) {
       result[key] = obj[key];
    }
    return result;
  }, {});
}

export function getBrowserDimensions() {

  if( typeof( window.innerWidth ) == 'number' ) {
  //Non-IE
    return {
      height: window.innerHeight,
      width: window.innerWidth
    };
  } else if( document.documentElement && ( document.documentElement.clientWidth || document.documentElement.clientHeight ) ) {
    //IE 6+ in 'standards compliant mode'
    return {
      height: document.documentElement.clientHeight,
      width: document.documentElement.clientWidth
    };
  } else if( document.body && ( document.body.clientWidth || document.body.clientHeight)){
    //IE 4 compatible
    return {
      height: document.body.clientHeight,
      width: document.body.clientWidth
    };
  }

  return {
    height: 0,
    width: 0
  };
}

// ------------------------ byte converters -----------------------------------

export function toBase64(blob: Blob): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

export function toArrayBuffer(blob: Blob): Promise<ArrayBuffer> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsArrayBuffer(blob);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

export function toBlob(fileInBase64: string, contentType: string = 'application/pdf'): Blob {

  const byteArray = toUint8Array(fileInBase64);

  return new Blob([byteArray], {type: contentType});
}

export function toUint8Array(fileInBase64: string): Uint8Array {

  const byteCharacters = atob(fileInBase64);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  return new Uint8Array(byteNumbers);
}
