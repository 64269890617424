// @flow

import React from 'react'

import RootElemWrapper from './src/components/RootElemWrapper';
import PageElemWrapper from './src/components/PageElemWrapper';

export const wrapPageElement = ({ element, props }) => {
  return <PageElemWrapper {...props}>{element}</PageElemWrapper>
}

export const wrapRootElement = ({ element }) => {
  return <RootElemWrapper>{element}</RootElemWrapper>
}
