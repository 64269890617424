// @flow

import React, {useEffect, useState} from 'react';

import { Provider } from 'react-redux';
import {StripeProvider} from 'react-stripe-elements';
import {init} from '../redux/storeInitialiser';
import {isPrerender} from '../lib/util';
import loadScript from '../lib/scriptLoader';
import stripeFactory from '../lib/stripeFactory';

//import { PersistGate } from 'redux-persist/lib/integration/react';

type Props = {
  +store: Object,
  +persistor: Object,
  +history: Object,
  +children?: any
}

const userInteractEvts = ['mousedown', 'mousemove', 'keypress', 'scroll', 'touchstart'];

function userInteractCleanup(f) {
  for (let evt of userInteractEvts) {
    window.removeEventListener(evt, f);
  }
}

const {store} = init();

const RootElemWrapper = (props: Props) => {

  const [cookiebotLoaded, setCookiebotLoaded] = useState(false);
  useEffect(() => {
    if (!isPrerender && !cookiebotLoaded) {
      loadScript({
        id: 'Cookiebot',
        url: 'https://consent.cookiebot.com/uc.js',
        async: false,
        defer: false,
        attribs: [
          {
            key: 'data-cbid',
            value: process.env.GATSBY_COOKIEBOT_CBID
          },
          // {
          //   key: 'data-blockingmode',
          //   value: 'auto'
          // }
        ]
      }, () => setCookiebotLoaded(true));
    }
  }, [cookiebotLoaded]);

  const [stripe, setStripe] = useState(null);
  useEffect(() => {

    if (!isPrerender && !stripe) {
      const loadStripe = () => loadScript({
        id: 'stripeScript',
        url: 'https://js.stripe.com/v3/',
        async: true,
        defer: true,
        callbackDelay: 5000
      }, () => {
        const newInstance = stripeFactory.getInstance();
        setStripe(newInstance);
        userInteractCleanup(loadStripe);
      });

      for (let evt of userInteractEvts) {
        window.addEventListener(evt, loadStripe);
      }

      return () => userInteractCleanup(loadStripe);
    }
  }, [stripe]);

  const {children} = props;

  return (
    <Provider store={store}>
      <StripeProvider stripe={stripe}>
        {children}
      </StripeProvider>
    </Provider>
  );
};

export default RootElemWrapper;
