// @flow

import "./index.css"

import {
  CloseOutlined,
  FacebookFilled,
  MenuOutlined
} from '@ant-design/icons';
import {Col, Layout, Menu, Row} from 'antd';
import React, {useState} from "react"

import AntdContainer from './AntdContainer';
import Link from "./Link"
import { StaticImage } from "gatsby-plugin-image";

const menuItems = [
  {path: '/send-letter', title: 'Send a letter'},
  {path: '/blog', title: 'Blog'},
  {path: '/faq', title: 'FAQ'},
  {path: '/pricing', title: 'Pricing'}
];

const NavMenu = (props: Object) => {

  const [openKeys, setOpenKeys] = useState([]);

  return (
    <Row>
      <Link style={{zIndex: 1000, position: 'absolute', top: '12px'}} id='logo' to='/' >
        <StaticImage alt='logo' layout='fixed' src='../images/jpg_png_g/logo_8_crop.png' width={144} />
      </Link>
      <Col xs={24} md={0}>
        <Menu openKeys={openKeys} onClick={() => setOpenKeys([])} onOpenChange={keys => setOpenKeys(keys)} className='nav' mode="inline" selectable={false} >
          <Menu.SubMenu className='mobile-menu' key='submenu' expandIcon={ openKeys.length ? <CloseOutlined className='pull-right top-space' style={{fontSize: '24px'}} /> : <MenuOutlined className='pull-right top-space' style={{fontSize: '24px'}}/>
          }>
            {
              menuItems.map((item, idx) => {
                return (
                  <Menu.Item className={'text-center mobile-menu-item'} {...props} key={item.path}>
                    <Link to={item.path}>
                      {item.title}
                    </Link>
                  </Menu.Item>
                )
              })
            }
          </Menu.SubMenu>
        </Menu>
      </Col>
      <Col xs={0} md={24}>
        <Menu className='nav' selectedKeys={props.selectedKeys} mode="horizontal">
          {
            menuItems.map(item => {
              return (
                <Menu.Item {...props} key={item.path}>
                  <Link to={item.path}>{item.title}</Link>
                </Menu.Item>
              );
            })
          }
        </Menu>
      </Col>
    </Row>
  );
}

const Navigator = (props: Object) => {

  const pathname = props && props.location && props.location.pathname;
  const selectedKeys = [pathname];

  return (
    <header className='header'>
      <div className='content-wrapper'>
        <AntdContainer>
          <NavMenu selectedKeys={selectedKeys} />
        </AntdContainer>
      </div>
    </header>
  )
}

const iconStyle = {
  fontSize: '24px'
}

const MyFooter = (props: Object) => {

  const year = new Date().getFullYear();

  return (
    <div className='dark footer'>
        <div className='content-wrapper top-space-2x'>
          <AntdContainer>
            <Row className='bottom-space-2x'>
              <Col>
                <div style={{color: '#a9a9a9'}} >
                  PostSeal ©{year}
                </div>
              </Col>
            </Row>
            <Row className='separated-list bottom-space top-space-2x'>
              <Col sm={10} xs={24}>
                <div >
                  <Link to='/send-letter'>Send a letter</Link>
                </div>
                <div >
                  <Link to='/'>Home</Link>
                </div>
                <div >
                  <Link to='/pricing'>Pricing</Link>
                </div>
                <div >
                  <Link to='/blog'>Blog</Link>
                </div>
              </Col>
              <Col sm={10} xs={24}>
                <div >
                  <Link to='/faq'>FAQ</Link>
                </div>
                <div >
                  <Link to='/terms'>Terms of Service</Link>
                </div>
                <div >
                  <Link to='/privacy-policy'>
                    Privacy Policy
                  </Link>
                </div>
                <div >
                  <Link to='/cookie-declaration'>
                    Cookie Declaration
                  </Link>
                </div>
              </Col>
              <Col sm={4} xs={24}>
                <div >
                  <Link target='_blank' rel="noopener noreferrer" to='https://www.facebook.com/Send-a-letter-online-PostSeal-108245447282661/'>
                    <FacebookFilled style={iconStyle} />  Facebook
                  </Link>
                </div>
                <div ><a href='mailto:info@postseal.com'>info@postseal.com</a></div>
              </Col>
            </Row>

          </AntdContainer>
        </div>
    </div>
  )
}

const MyLayout = (props: Object) => {

  return (
    <Layout>
      <Navigator {...props} />
      {props.children}
      <MyFooter />
    </Layout>
  )
}

export default MyLayout
