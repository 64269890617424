// @flow

const initialState = {
  sending: false
}

export default function contactUsReducer(state: Object = initialState, action: Object) {
  switch(action.type) {
    case 'CONTACT_US': {
      return {
        ...state,
        sending: true
      }
    }
    case 'CONTACT_US_SUCCESS': {
      return {
        ...state,
        sending: false
      }
    }
    case 'ERROR': {
      const {errorType} = action.payload;
      if (errorType === 'CONTACT_US') {
        return {
          ...state,
          sending: false
        }
      }
    }
    // no default
  }

  return state;
}
