// @flow

const logService = typeof window !== `undefined` ? require("./LogEntries").default : console

const isDev = process.env.NODE_ENV !== 'production';

function assert(condition: boolean, ...data: Array<any>): void {
  if (isDev) {
    console.assert(condition, data);
  }
}

function clear(): void {
  if (isDev) {
    console.clear();
  }
}

function count(label: string): void {
  if (isDev) {
    console.count(label);
  }
}

function debug(...data: Array<any>): void {
  if (isDev) {
    console.debug(...data);
  }

  logService.log(JSON.stringify(data));
}

function dir(...data: Array<any>): void {
  if (isDev) {
    console.dir(...data);
  }
}

function dirxml(...data: Array<any>): void {
  if (isDev) {
    console.dirxml(...data);
  }
}

function error(...data: Array<any>): void {
  if (isDev) {
    console.error(...data);
  } else {
    logService.error(JSON.stringify(data));
  }
}

function _exception(...data: Array<any>): void {
  if (isDev) {
    console._exception(...data);
  }
}

function group(...data: Array<any>): void {
  if (isDev) {
    console.group(...data);
  }
}

function groupCollapsed(...data: Array<any>): void {
  if (isDev) {
    console.groupCollapsed(...data);
  }
}

function groupEnd(): void {
  if (isDev) {
    console.groupEnd();
  }
}

function info(...data: Array<any>) {
  if (isDev) {
    console.info(...data);
  } else {
    logService.info(JSON.stringify(data));
  }
}

function log(...data: Array<any>) {
  if (isDev) {
    console.log(...data);
  } else {
    logService.log(JSON.stringify(data));
  }
}

function profile(name: string): void {
  if (isDev) {
    console.profile(name);
  }
}

function profileEnd(): void {
  if (isDev) {
    console.profileEnd();
  }
}

function table(tabularData: { [key: string]: any } | Array<Array<any>>): void {
  if (isDev) {
    console.table(tabularData);
  }
}

function time(label: string): void {
  if (isDev) {
    console.time(label);
  }
}

function timeEnd(label: string): void {
  if (isDev) {
    console.timeEnd(label);
  }
}

function timeStamp(label?: string): void {
  if (isDev) {
    console.timeStamp(label);
  }
}

function trace(...data: Array<any>): void {
  if (isDev) {
    console.trace(...data);
  }
}

function warn(...data: Array<any>) {
  if (isDev) {
    console.warn(data);
  } else {
    logService.warn(JSON.stringify(data));
  }
}

export default {
  assert,
  clear,
  count,
  debug,
  dir,
  dirxml,
  error,
  _exception,
  group,
  groupCollapsed,
  groupEnd,
  info,
  log,
  profile,
  profileEnd,
  table,
  time,
  timeEnd,
  timeStamp,
  trace,
  warn
}
