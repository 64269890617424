// @flow

import {call, put} from 'redux-saga/effects';

import type {Countries} from '../../types';
import {addDays} from '../../lib/util';
import axios from 'axios';
import {fetchCountriesSuccess} from '../actions/entitiesActions';
import {fireError} from '../actions/statusActions';
import { message } from 'antd';

export function* fetchCountries(): Generator<*, void, *> {
  const URL = `${process.env.GATSBY_API_URL}/v2/entities/countries`;

  try {
    const res = yield call(axios.get, URL);
    const countries: Countries = res.data;
    const expiresAt = yield call(addDays, new Date(), 180);
    yield put(fetchCountriesSuccess(countries, expiresAt.toISOString()));
  } catch (e) {
    const serverErrMsg = e.response && e.response.data ? e.response.data : e.message;
    const fullMsg = `Failed to load countries. ${serverErrMsg}`;
    message.error(fullMsg);

    yield put(fireError(fullMsg, 'FETCH_COUNTRIES'));
  }
}
 
