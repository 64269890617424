// @flow

import type { Countries, Country, State } from '../types';

import { createSelector } from 'reselect';
import {getCountriesState} from './index';

export function getCountriesById(state: State): Countries {
  return getCountriesState(state).byId;
} 

export function canAutoFetchCountries(state: State) {
  return getCountriesState(state).canAutoFetch;
}

export function areCountriesLoading(state: State): boolean {
  return getCountriesState(state).isLoading;
}

export function getCountry(state: State, countryCode: string): Country {
  return getCountriesById(state)[countryCode];
}

export function getCountryName(state: State, countryCode: ?string): ?string {
  if (countryCode) {
    const country = getCountry(state, countryCode);
    if (country) {
      return country.name;
    }
  }
  return null;
}

export const getUICountries = createSelector(
  getCountriesById, countriesById => {

    const uiCountries = [];
    const keys = Object.keys(countriesById);
    for (let i = 0, len = keys.length; i < len; i++) {
      const key = keys[i];
      const uiCountry = {
        label: countriesById[key].name,
        value: key
      };
      uiCountries.push(uiCountry);
    };

    return uiCountries;
  }
);
