// @flow

import {Col, Row} from 'antd';

import React from 'react';

const AntdContainer = (props: Object) => {
  const className = props.className ? `ant-container ${props.className}` : 'ant-container';

  return (
    <Row className={className}>
      <Col xs={{span: 24}} sm={{offset: 1, span: 22}}>
        {props.children}
      </Col>
    </Row>
  );
}
 
export default AntdContainer;
