// @flow

//import { connectRouter } from 'connected-react-router';
//import localForage from 'localforage';
//import { persistReducer } from 'redux-persist';
import { all, takeLatest } from 'redux-saga/effects';
import {contactUs, estimatePrice} from './sagas/miscSagas';

import contactUsReducer from './reducers/contactUsReducer';
import countriesReducer from './reducers/countriesReducer';
//import {customMigrate} from './reducers';
import { fetchCountries } from './sagas/entitiesSagas';
import priceEstimateReducer from './reducers/priceEstimateReducer';

function* rootSaga(args: Array<any>): Generator<*, void, *> {
  yield all([
    //takeEvery(action => isPrerender, setPreloadedState),
    takeLatest('CONTACT_US', contactUs),
    takeLatest('FETCH_COUNTRIES', fetchCountries),
    takeLatest('ESTIMATE_PRICE', estimatePrice)
  ]);
}

// function* setPreloadedState(action) {
//   window.__PRELOADED_STATE__ = yield select();
// } 

export default function getMainModule(history: Object) {
  return {
    id: 'mainModule',
    reducerMap: {
      //router: connectRouter(history),
      contactUs: contactUsReducer,
      priceEstimate: priceEstimateReducer,
      countries: countriesReducer,
      // countries: persistReducer({
      //   key: COUNTRIES,
      //   storage: localForage,
      //   migrate: customMigrate
      // }, countriesReducer)
    },
    sagas: [rootSaga]
  }
}
