// @flow

//import ScrollToTop from './ScrollToTop';
import LayoutWrapper from './LayoutWrapper';
import React from 'react';
import withTracker from './hoc/withTracker';

const PageElemWrapper = props => {

  const {children, ...otherProps} = props;

  // I've removed ScrollToTop, it looks like gatsby does this on url change by default?

  return (
    <LayoutWrapper {...otherProps}>
      {children}
    </LayoutWrapper>
  );
}

export default withTracker(PageElemWrapper);
 
