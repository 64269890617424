// @flow

//import { persistStore } from 'redux-persist';
//import { /*createStore,*/ applyMiddleware } from 'redux';
import { IModuleStore, createStore } from 'redux-dynamic-modules';

import { createLogger } from 'redux-logger';
//import { createBrowserHistory } from 'history';
//import { routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
//import rootSaga from '../actions/sagas';
//import createRootReducer from '../reducers';
import getMainModule from './mainModule';
import {getSagaExtension} from 'redux-dynamic-modules-saga';
import logger from '../lib/logger';

// dev tools doesn't work with dynamic modules?
//import {composeWithDevTools} from 'remote-redux-devtools';
//import devToolsEnhancer from 'remote-redux-devtools';

const defaultLoggerOptions = process.env.NODE_ENV === 'production' ? {
  logger,
  collapsed: true,
  diff: false,
  colors: {},
  titleFormatter: (action, time, took) =>  `${String(action.type)} @ ${time}`,
  // comment out if want to view prev/next state in dev
  stateTransformer: () => '',
} : {
  logger,
  collapsed: true
};

let persistor;
export function init(onRehydrated: ?Function, loggerOptions: Object = defaultLoggerOptions): Object {

  logger.info('initialising redux store');

  const history = {} //createBrowserHistory();

  // NOTE: redux-logger prints the whole state tree, which is probably too much.
  // Might want use LogRocket or change the logger options
  const loggerMiddleware = createLogger(loggerOptions);
  const sagaMiddleware = createSagaMiddleware();
  // const composeEnhancers = composeWithDevTools({
  //   // Specify name here, actionsBlacklist, actionsCreators and other options if needed
  // });
  //const routingMiddleware = routerMiddleware(history);


  const initialState = {};

  const store: IModuleStore = createStore({
    initialState,
    //enhancers: [devToolsEnhancer()],
    extensions: [getSagaExtension(), {
      // https://github.com/microsoft/redux-dynamic-modules/issues/111
      middleware: [loggerMiddleware, sagaMiddleware, /*routingMiddleware*/]
    }]
  }, getMainModule(history));

  //persistor = createPersistor(store, onRehydrated);

  return {
    store,
    history,
    persistor: null // not using persistor anymore, but might bring it back...
  }
}

// HACKY, waiting for redux-persist code splitting support
export function getPersistor() {
  return persistor;
}

// function createPersistor(store: Object, onRehydrated: ?Function): ?Object {
//   if (isPrerender) {
//     // NOTE: hack, which wouldn't work if prerender result relied on rehydrated state
//     setTimeout(() => {
//       if (onRehydrated) onRehydrated();
//     }, 0);
//     return null;
//   }
//   const persistor = persistStore(store, null, () => {
//     if (onRehydrated) {
//       onRehydrated();
//     }
//   });
//   return persistor;
// }
