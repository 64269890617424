// @flow

import type {EstimatePriceActionPayload} from '../../types';

export function contactUs(email: string, message: string, resetFields: Function) {
  return {
    type: 'CONTACT_US',
    payload: {
      email,
      message,
      resetFields
    }
  };
}

export function contactUsSuccess() {
  return {
    type: 'CONTACT_US_SUCCESS',
    payload: {}
  };
}

export function estimatePrice(payload: EstimatePriceActionPayload) {
  return {
    type: 'ESTIMATE_PRICE',
    payload
  };
}

export function estimatePriceSuccess(price: number) {
  return {
    type: 'ESTIMATE_PRICE_SUCCESS',
    payload: {
      price
    }
  };
}
