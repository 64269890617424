const le = require('./le.js');

function Logger(token, opts={}){
    if(!token){
        throw new Error ('A valid token is required for all LogEntries library implementations.');
    }

    opts.token = token;
    le.init(opts);
    return le;
}

const LogEntries = new Logger(process.env.GATSBY_LOGENTRIES_TOKEN);

export default LogEntries;
 
