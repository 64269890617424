// @flow

export function fireError(errorMsg: string, errorType: ?string, metaInfo: any) {
  return {
    type: 'ERROR',
    payload: {
      errorMsg,
      errorType,
      metaInfo
    }
  }
}

export function resetErrorMsg() {
  return {
    type: 'RESET_ERROR_MSG',
    payload: {}
  }
}

export function resetSuccessMsg() {
  return {
    type: 'RESET_SUCCESS_MSG',
    payload: {}
  }
}
