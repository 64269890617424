// @flow

//import { REHYDRATE } from 'redux-persist';

import type { Action, CountriesState } from '../../types';

//import {COUNTRIES} from '../../constants/OBJECT_KEYS';

const initialState: CountriesState = {
  byId: {},
  persistExpiresAt: null,
  isLoading: false,
  canAutoFetch: true
};

export {
  initialState
}

export default function countriesReducer(state: CountriesState = initialState, action: Action): CountriesState {
  switch (action.type) {
    // case REHYDRATE: {
    //   if (action.key === COUNTRIES && action.payload) {
    //     return {
    //       ...state,
    //       ...action.payload,
    //       canAutoFetch: Object.keys(action.payload.byId).length === 0
    //     };
    //   }
    //   break;
    // }
    case 'FETCH_COUNTRIES': {
      return {
        ...state,
        isLoading: true,
        canAutoFetch: false
      }
    }
    case 'FETCH_COUNTRIES_SUCCESS': {
      return {
        ...state,
        byId: action.payload.countriesById,
        persistExpiresAt: action.payload.persistExpiresAt,
        isLoading: false
      }
    }
    case 'ERROR': {
      if(action.payload.errorType === 'FETCH_COUNTRIES') {
        return {
          ...state,
          isLoading: false
        };
      }
      break;
    }
    // no default
  }

  return state;
}
