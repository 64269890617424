// @flow

import {useDispatch, useSelector} from 'react-redux';

import { Link as GatsbyLink } from "gatsby";
import React from 'react';
import {canAutoFetchCountries} from '../redux/selectors/countriesSelectors';
import {fetchCountries} from '../redux/actions/entitiesActions';

// Since DOM elements <a> cannot receive activeClassName
// and partiallyActive, destructure the prop here and
// pass it only to GatsbyLink
const Link = ({ children, to, activeClassName, partiallyActive, onMouseOver, ...other }) => { 

  const canFetchCountries = useSelector(canAutoFetchCountries);
  const dispatch = useDispatch();

  // This example assumes that any internal link (intended for Gatsby)
  // will start with exactly one slash OR will be made of full URL, e.g. postseal.com/* (for services like contentful), and that anything else is external.
  const isInternal = /^\/(?!\/)/.test(to) || to.indexOf(process.env.GATSBY_CLIENT_URL) >=0;

  // Use Gatsby Link for internal links, and <a> for others
  //if (!isInternal) {
  if (!isInternal || other.target === '_blank') {
  //if (true) {
    return (
      <a href={to} {...other}>
        {children}
      </a>
    );
  }

  const _onMouseOver = onMouseOver || (() => {
    if (to.indexOf('/pricing') >= 0 || to.indexOf('/send-letter') >= 0) {
      if (canFetchCountries) {
        dispatch(fetchCountries());
      }
    }
  });

  return (
    <GatsbyLink
      to={to}
      activeClassName={activeClassName}
      partiallyActive={partiallyActive}
      onMouseOver={_onMouseOver}
      {...other}
    >
      {children}
    </GatsbyLink>
  )
}

export default Link
