// @flow

import React, { Component } from 'react';
import GoogleAnalytics from 'react-ga';
import logger from '../../lib/logger';
import {isPrerender} from '../../lib/util';
import loadScript from '../../lib/scriptLoader';

type Props = {
  +location: {
    +pathname: string
  }
}

let isGAInitialized = false;

function canRunGA() {
  if (!isPrerender) {
    const {Cookiebot} = window;
    return process.env.GATSBY_FORCE_GA === 'TRUE' || (!!Cookiebot && !!Cookiebot.consent && !!Cookiebot.consent.statistics);
  }

  return false;
}

function canInitGA() {
  return !isGAInitialized && canRunGA();
}

// Could switch to google tag manager + cookiebot https://www.cookiebot.com/en/google-tag-manager-gdpr/
function _initGA() {
  GoogleAnalytics.initialize(process.env.GATSBY_GA_TRACKING_ID);

  loadScript({
    id: 'gTagManager',
    url: `https://www.googletagmanager.com/gtag/js?id=${process.env.GATSBY_GTAG_ID}`,
    async: true,
    defer: true
  }, () => {
    window.dataLayer = window.dataLayer || [];
    window.gtag = window.gtag || ((...args) => {
      window.dataLayer.push(args)
    });
    window.gtag('js', new Date());
    window.gtag('config', process.env.GATSBY_GTAG_ID);
  });

  isGAInitialized = true;
}

// TODO add CookiebotOnDecline listener and remove google analytics!
if (!isPrerender) {
  window.addEventListener('CookiebotOnAccept', (e: Object) => {
    const {consent} = window.Cookiebot;
    logger.info(`CookiebotOnAccept called, consent = ${JSON.stringify(consent)}`);
    if (canInitGA()) {
      _initGA();
    }
  });
}

const withTracker = (WrappedComponent: any, options: Object = {}) => {

  if (isPrerender) {
    return WrappedComponent;
  }

  if (canInitGA()) {
    _initGA();
  }

  const trackPage = page => {
    GoogleAnalytics.set({
      page,
      ...options,
    });
    GoogleAnalytics.pageview(page);
  };

  const HOC = class extends Component<Props> {
    componentDidMount() {

      if (canRunGA()) {
        const page = this.props.location.pathname;
        trackPage(page);
      }
    }

    componentWillReceiveProps(nextProps: Props) {
      const currentPage = this.props.location.pathname;
      const nextPage = nextProps.location.pathname;

      if (currentPage !== nextPage && canRunGA()) {
        trackPage(nextPage);
      }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };

  return HOC;
};

export default withTracker;
