// @flow

import type {
  CountriesState, LettersState, LetterFilesMetaState, State
} from '../../types';

export function getCountriesState(state: State): CountriesState {
  return state.countries || state;
}

export function getLettersState(state: State): LettersState {
  return state.letters || state;
}

export function getLetterFilesMetaState(state: State): LetterFilesMetaState {
  return state.letterFilesMeta || state;
}

export function getContactUsState(state: State): Object {
  return state.contactUs || state;
}

export function getPriceEstimateState(state: State): Object {
  return state.priceEstimate || state;
}

export function getFilesState(state: State): Object {
  return state.files || state;
}
