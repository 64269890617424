// @flow

export function fetchCountries() {
  return {
    type: 'FETCH_COUNTRIES',
    payload: {}
  }
}

export function fetchCountriesSuccess(countriesById: Object, persistExpiresAt: ?string) {
  return {
    type: 'FETCH_COUNTRIES_SUCCESS',
    payload: {
      countriesById,
      persistExpiresAt
    }
  }
}
