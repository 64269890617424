// @flow

const initialState = {
  estimating: false,
  price: 0
}

export default function priceEstimateReducer(state: Object = initialState, action: Object) {
  switch(action.type) {
    case 'ESTIMATE_PRICE': {
      return {
        ...state,
        estimating: true
      }
    }
    case 'ESTIMATE_PRICE_SUCCESS': {
      return {
        ...state,
        estimating: false,
        price: action.payload.price
      }
    }
    case 'ERROR': {
      const {errorType} = action.payload;
      if (errorType === 'ESTIMATE_PRICE') {
        return {
          ...state,
          estimating: false
        }
      }
    }
    // no default
  }

  return state;
}
